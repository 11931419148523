.mapBox {
  // position: absolute;
  top: 0;
  // bottom: 0;
  width: 100%;
  // height: 100%;
}

.mapContainer {
  position: absolute;
  // top: inherit;
  // bottom: inherit;
  width: 100%;
//  height: calc(100% - 30px);
  height: calc(100% - 00px);
}

.boxdraw {
  background: rgba(56, 135, 190, 0.1);
  border: 2px solid #3887be;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.overlay {
  padding: 10px;
}

// .map-overlay {
//   font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
//   position: absolute;
//   // width: auto;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   padding: 10px;
// }

// .map-overlay .map-overlay-inner {
//   background-color: #fff;
//   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
//   border-radius: 3px;
//   padding: 10px;
//   margin: 10px;
//   margin-bottom: 10px;
// }

// .map-overlay h2 {
//   line-height: 24px;
//   display: block;
//   margin: 0 0 10px;
// }

// .map-overlay .legend .bar {
//   height: 10px;
//   width: 100%;
//   background: linear-gradient(to right, #fca107, #7f3121);
// }

// .map-overlay input {
//   background-color: transparent;
//   display: inline-block;
//   width: 100%;
//   position: relative;
//   margin: 0;
//   cursor: ew-resize;
// }

.legend {
  background-color: #fff;
  border-radius: 3px;
  bottom: 30px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  right: 10px;
  z-index: 1;
}

.legend h4 {
  margin: 0 0 10px;
}

.legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}
