.axis line,
.axis path {
  shape-rendering: crispEdges;
  fill: transparent;
  stroke: #555;
}
.axis text {
  font-size: 11px;
}


.line {
  fill-opacity: 0.5;
}
.age14line {
  fill: none;
//  stroke: #ed3700;
  stroke: steelblue
}
.age64line {
  fill: none;
  stroke: #2b2929;
//  stroke-dasharray: 0;
}
.age65line {
  fill: none;
  stroke: #9c9c9c;
//  stroke-dasharray: 0;
}

svg {
//    font-family: Sans-Serif, Arial;
}
.line {
  stroke-width: 2;
  fill: none;
}

.axis path {
  stroke: black;
}

.text {
  font-size: 12px;
}

.title-text {
  font-size: 12px;
}
